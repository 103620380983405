import React from "react"
import Layout from "../../components/layout"
import Hero from "../../components/hero"

export default () => (
  <>
    <Layout>
      <Hero />

        {/* Style Guide Section
        ================================================== */}
        <section id="styles"
          style= {{
            padding: '90px 40px 72px',
            background: '#fff'
          }}
        >
          <div className="row section-head">
            <h1>CONDITIONS GÉNÉRALES D’UTILISATION</h1>
          </div>

           <div className="row" >


                 <h5>Article 1 : OBJET</h5>

                 
                  <div>Les présentes Conditions Générales d’Utilisation encadrent juridiquement l’utilisation des services du site  <b>Juliette Chastel - Traductrice</b>. L’accès au site signifie l’acceptation des présentes CGU.</div>
                

                 <h5>Article 2 : MENTIONS LEGALES</h5>
                 <ul >
                <li>Juliette Chastel - Traductrice</li>
                <li>Siège social : 115 rue de Reuilly, 75012 PARIS</li>
                <li>Mail : jchastel.traduction@gmail.com Téléphone : 0173710384</li>
                <li>Régime de microentreprise</li>
                <li>N° de SIRET : 904 556 156 00019</li>
                <li>Dispensée d’immatriculation au Registre du commerce et des sociétés (RCS)</li>
                <li>Représentante légale : Juliette Chastel</li>
                <li>Hébergement : Netlify, Inc. Siège social : 2325 3RD STREET,SUITE 215, SAN FRANCISCO, CA 94107 (USA).</li>
                <li>Création site internet : Pierrick Houede.</li>
                </ul>
                <h5>Article 3 : ACCÈS AUX SERVICES</h5>
                
                  <div>Le site <b>Juliette Chastel – Traductrice</b> permet d’accéder aux services suivants :</div>
                
                <ul >
                <li>Services de traduction et de correction</li>
                <li>Services de transcription et de sous-titrage</li>
                <li>Services terminologiques et rédactionnels</li>
                </ul>
                

                <h5>Article 4 : COLLECTE DES DONNÉES</h5>

                 
                  <div>L’adresse électronique (e-mail) fournie par l’Utilisateur pourra être utilisée par le site <b>Juliette Chastel – Traductrice</b>, pour la communication d’informations diverses.
                     </div>
                     <div><b>Juliette Chastel – Traductrice</b> garantit le respect de la vie privée de l’Utilisateur, conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.
                     </div>
                     <div>En vertu des articles 39 et 40 de la loi en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles.
                     </div>
                     <div>L'Utilisateur exerce ce droit par mail à jchastel.traduction@gmail.com
                     </div>
                

                <h5>Article 5 : PROPRIÉTÉ INTELLECTUELLE</h5>

                 
                  <div>Le logo ainsi que les contenus du site <b>Juliette Chastel - Traductrice</b> sont protégés par le Code de la propriété intellectuelle et par le droit d’auteur.
                     </div>
                <div>La reproduction et la copie des contenus par l’Utilisateur requièrent une autorisation préalable du site. Dans ce cas, toute utilisation à des usages commerciaux ou à des fins publicitaires est proscrite.
                    </div>
                
            </div>

           

        </section> {/* Style Guide Section End*/}

    </Layout>
  </>
)